/* You can add global styles to this file, and also import other style files */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'preflight.scss';
// @import 'primeng/resources/themes/saga-blue/theme.css';
@import 'primeng/resources/themes/lara-light-blue/theme.css';
// @import 'primeng/resources/themes/lara-light-teal/theme.css';
// @import 'primeng/resources/themes/saga-green/theme.css';
// @import 'primeng/resources/themes/fluent-light/theme.css';
// @import 'primeng/resources/themes/mdc-light-deeppurple/theme.css';
// @import 'primeng/resources/themes/mdc-light-indigo/theme.css';
@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';
@import 'font-awesome/css/font-awesome.css';
@import 'font-woff2-embedded.css';
@import 'override-styles.scss';
@import 'shepherd.js/dist/css/shepherd.css';

html {
  height: 100%;
  overflow: hidden;
  font-size: 14px; // Ändern zum Anpssen der gesamten Größen
}

body {
  height: 100%;
  overflow: hidden;
  // user-select: none;
  background-color: white;
}

/** Böser hack um Problem im KP Editor zu umgehen!! **/
.p-accordion .p-toggleable-content {
  overflow: hidden !important;
}

.tb-table .p-treetable-tbody > tr > td,
.tb-table .p-table-tbody > tr > td {
  padding: 0.1rem 0.1rem !important;
}

.p-dropdown-sm .p-dropdown-label,
.p-dropdown-sm .p-dropdown-item > span {
  font-size: 0.875rem;
  padding: 0.4375rem 0.4375rem;
}

.kp-item-list {
  & .p-orderlist-list-container {
    @apply flex;
    @apply flex-col;
  }

  .cdk-drop-list.p-orderlist-list {
    height: unset !important;
    @apply flex;
    @apply flex-grow;
    @apply flex-col;
  }

  li {
    min-height: 4rem;
    vertical-align: middle;
  }
}

.kp-kopf-list {
  & .p-toggleable-content {
    @apply flex;
    @apply flex-grow;

    & .p-panel-content {
      @apply flex;
      @apply flex-grow;
    }
  }
}

.tb-dialog .p-dialog-content {
  padding: 0;
}
.note-1 {
  @apply w-12;
  & span {
    background-color: #689f38;
  }
}
.note-2 {
  @apply w-12;
  & span {
    background-color: #006775;
  }
}
.note-3 {
  @apply w-12;
  & span {
    background-color: #fbc02d;
  }
}
.note-4 {
  @apply w-12;
  & span {
    background-color: #6d1b7b;
  }
}
.note-5 {
  @apply w-12;
  & span {
    background-color: #d32f2f;
  }
}
.note-ka {
  @apply w-12;
  & span {
    background-color: #0288d1;
  }
}
.note-default {
  @apply w-12;
  & span {
    background-color: #0288d1;
  }
}

.w-10rem {
  width: 10rem;
}
.w-noteneingabe {
  width: 6rem;
}

.fz-paragraph-text {
  @apply leading-6;
  p {
    @apply pt-2;
  }
  li {
    @apply pt-1;
  }
}

fz-formular-sidebar {
  button.p-button-text {
    @apply pl-0 pr-0;
  }
  p-radiobutton {
    @apply pt-1;
  }
  p-checkbox {
    @apply pt-1;
  }
}
