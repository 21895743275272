/* You can add global styles to this file, and also import other style files */
.fz-menu-overlay .p-overlaypanel-content {
  padding: 0;
}
.fz-menu-overlay > div {
  @apply w-64;
}

.fz-usermenu-overlay > div {
  @apply w-96;
}

.fz-menu-overlay.p-overlaypanel {
  margin-top: 0;
}

.navigate-to-zeugnis span.p-button-icon {
  font-size: 1.75rem;
}

.fz-tabview-space-left.p-tabview .p-tabview-nav-container {
  @apply ml-16;
}
.fz-tabview-space-right.p-tabview .p-tabview-nav-container {
  @apply mr-16;
}

.fz-tabview-scroll.p-tabview .p-tabview-panels {
  @apply relative flex-grow p-0;
  // height: 100%;
}

.fz-tabview-scroll.p-tabview.p-component {
  @apply flex-grow flex flex-col;
}

.fz-tabview-scroll.p-tabview .p-tabview-panels .p-tabview-panel {
  @apply flex-grow flex flex-col;
}

.fz-flex-panel {
  @apply flex-grow flex flex-col;
}
.fz-flex-panel .p-toggleable-content {
  @apply flex-grow flex flex-col;
}
.fz-flex-panel .p-panel-content {
  @apply flex-grow flex flex-col;
}

p-tabpanel.fz-select-panel {
  @apply h-full w-full;
}

.fz-validation-error {
  color: red;
}

.fz-button-group {
  @apply flex gap-4;
}

.fz-system-restore-uploader {
  display: none !important;
}
.fz-zuordnung-delete-btn span {
  font-size: 0.75rem;
}

.p-grid {
  @apply m-0;
}

.fz-tree-node * button.p-tree-toggler.p-link.p-ripple {
  // display: none;
  width: 0 !important;
}

.fz-blanko-table .p-datatable-thead th,
.fz-blanko-table .p-datatable-tbody td {
  border-width: 2px !important;
}

.fz-big-toast.p-toast {
  width: auto;
  max-width: 90%;
}
.fz-migration-upload {
  display: none !important;
}

.fz-kp-picklist .p-picklist-header {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}

.fz-kp-picklist .p-picklist-filter-container {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}

.fz-kp-picklist .p-picklist-item {
  padding: 0.3rem 0.5rem 0.3rem 0.5rem;
}

.fz-preview div {
  @apply flex;
}

.p-message-custom {
  background-color: #e1cfe7;
  border: solid #8a427a;
  border-width: 0 0 0 6px;
  color: #2c1e30;
}
.fz-update-message p-messages div div {
  margin: 0;
  height: 42px;
}
.fz-update-message,
.pointer {
  cursor: pointer;
}

@media (min-width: 768px) {
  .md\:w-login {
    width: 36rem;
  }
}
.fz-schuelerliste-importtable .p-datatable .p-datatable-tbody > tr > td {
  padding: 0 !important;
}
.fz-messages-small .p-message {
  margin: 0;
  .p-message-wrapper {
    padding: 1px 5px;
  }
}

.fz-dropdown-primary {
  .p-dropdown {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    .p-inputtext {
      color: var(--primary-color-text);
    }
    .p-dropdown-trigger {
      color: var(--primary-color-text);
    }
  }
}

.fz-steps-transparent {
  .p-steps .p-steps-item .p-menuitem-link {
    background-color: transparent;
  }
}

.fz-panel-header-padding {
  .p-panel .p-panel-header {
    @apply p-7
  }
}